import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { api } from 'api/index';
import { Button } from 'components/Button';
import { ForkPageCard } from 'components/ForkPageCard';
import { Preloader } from 'components/Preloader';
import { paths } from 'config/paths';
import { CertificationDTO } from 'interfaces/certification';
import { theme } from 'theme';

import { Wrapper } from './style';

type RouteParams = {
  certificationSlug: string;
};

type ForkState = {
  loading: boolean;
  data: CertificationDTO | null;
  error: Error | null;
};

export const Fork: React.FC = () => {
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [state, setState] = useState<ForkState>({
    loading: true,
    data: null,
    error: null
  });
  const [certCount, setCertCount] = useState(0);

  const KB_URL = 'https://kb.institute.centralreach.com';
  const userId = localStorage.getItem('userId');
  const courseId = localStorage.getItem('courseId');

  useEffect(() => {
    Promise.all([
      api.cert.getCertificationBySlugAPI(params.certificationSlug),
      api.cert.getCertificationsAPI()
    ])
      .then(([certification, certifications]) => {
        setState({
          loading: false,
          data: certification,
          error: null
        });
        setCertCount(certifications.length);
      })
      .catch((error) => {
        setState({
          loading: false,
          data: null,
          error
        });
      });
  }, [params]);

  if (state.loading) {
    return <Preloader />;
  }

  return (
    <Wrapper>
      <div className='welcome'>
        <p className='welcome__title'>
          {certCount > 1 && (
            <Button
              text='Back'
              className='header--back'
              paddingX={9}
              paddingY={3}
              mode='prev'
              color={theme.palette.white}
              textColor={theme.palette['dark-lavender']}
              borderColor={theme.palette['dark-lavender']}
              arrowColor={theme.palette['dark-lavender']}
              hoverArrowColor={theme.palette.white}
              onClick={() => history.push(paths.home())}
            />
          )}
          <span className='welcome__text'>
            {state.data?.certification} Certification
          </span>
        </p>
      </div>
      <div className='cards'>
        <ForkPageCard
          title='SAFMEDS'
          description='Practice SAFMEDS cards to build fluency in ABA definitions, concepts, and examples'
          buttonHref={`${KB_URL}${paths.safmeds()}?user_id=${userId}&courseId=${courseId}`}
          buttonText='Practice with SAFMEDS'
        />
        <ForkPageCard
          title='MOCK EXAM'
          description='Take a mock exam to practice for the exam and test your knowledge'
          buttonHref={`${KB_URL}${paths.mockExam(
            params.certificationSlug
          )}?user_id=${userId}&courseId=${courseId}`}
          buttonText='Try a Mock Exam'
        />
      </div>
    </Wrapper>
  );
};
